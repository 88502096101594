import {useEventsQuery} from '~/graphql/graphql';

export const useEvents = () => {
  const {result} = useEventsQuery();

  const events = computed(() => {
    return result.value?.events ?? [];
  });

  return {
    events,
  };
};
