<template>
  <SlidersCalendar
    :months="availableDates"
    @change="setMonths"
  >
    <template #bottom>
      <TransitionsFade>
        <div
          v-if="!selectedEvents?.length"
          class="heading-sub py-5 md:py-8 lg:py-10"
        >
          {{ $t('No agenda items found') }}.
        </div>

        <TransitionGroup
          v-else
          name="slide-in-list"
          tag="ul"
          class="grid divide-y divide-black/10 lg:my-10 lg:grid-cols-2"
        >
          <li
            v-for="event in showEvents"
            :key="event.id"
            class="py-5 lg:mr-32 lg:[&:nth-child(2)]:border-none"
          >
            <EventLink :event="event" />
          </li>
        </TransitionGroup>
      </TransitionsFade>
    </template>
  </SlidersCalendar>
</template>

<script setup lang="ts">
import type {EventsQuery} from '~/graphql/graphql';

const props = defineProps<{
  events: EventsQuery['events']
  max?: number
}>();

function setMonths(months) {
  months = months.filter(v => v);

  selectedMonths.value = months.length ? months : [(new Date()).setMonth((new Date(availableDates.value[0])).getMonth())];
}

function monthValue(date: Date | string) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return '' + date.getFullYear() + date.getMonth();
}

const events = toRef(() => props.events);

const availableDates = computed(() => [...new Set(events.value.map((item) => {
  return item.start_date;
}))]);

const selectedMonths = ref<int[]>([]);

const selectedEvents = computed(() => events.value.filter((item) => {
  if (selectedMonths.value && selectedMonths.value.length) {
    return monthValue(item.start_date) === monthValue(selectedMonths.value[0]);
  }

  return true;
}));

const showEvents = computed(() => props.max ? selectedEvents.value?.slice(0, props.max) : selectedEvents.value);
</script>
