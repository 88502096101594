<template>
  <swiper
    class="!overflow-visible"
    :space-between="10"
    slides-per-view="auto"
    free-mode
    :mousewheel="{forceToAxis: true}"
    :modules="[SwiperFreeMode, SwiperMousewheel]"
  >
    <swiper-slide
      v-for="(month, index) in displayableMonths"
      :key="index"
      class="!w-fit"
    >
      <button
        class="btn select-none whitespace-nowrap capitalize duration-300"
        :class="selectedMonths.includes(month) ? 'text-white bg-blue' : 'text-blue bg-white'"
        @click="toggleMonth(month)"
      >
        {{ formatDate(month, 'MMMM yyyy' ) }}
      </button>
    </swiper-slide>

    <template #container-end>
      <slot name="bottom" />
    </template>
  </swiper>
</template>

<script setup lang="ts">
import {useLocaleDate} from '~/composables/useLocaleDate';

const props = defineProps<{
  months: Array<Date>,
}>();

const emit = defineEmits<{
  (e: 'change', months: Date[]): void
}>();

const formatDate = useLocaleDate();

const months = toRef(() => props.months);
const displayableMonths = computed(() => {
  const parsedMonths = months.value.map(m => (new Date()).setFullYear(new Date(m).getFullYear(), new Date(m).getMonth()));

  return checkDuplicates(parsedMonths);
});

function checkDuplicates(months: number[]) {
  return [...new Set(months)];
}

const selectedMonths = ref<int[]>([]);

watch(displayableMonths, (months) => {
  if (months.length > 0 && selectedMonths.value.length === 0) {
    selectedMonths.value = [months[0]];
  }
}, {immediate: true});

watch(selectedMonths, (months) => {
  emit('change', months);
});

onMounted(() => {
  emit('change', selectedMonths.value);
});

function toggleMonth(month: Date) {
  selectedMonths.value = selectedMonths.value.includes(month) ? selectedMonths.value.filter(m => m === month) : [month];
}
</script>
